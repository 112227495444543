<template lang='pug'>
head-meta(title-page="Результаты поиска")
the-search(@sendRequest="updateSearch",
  :query = "query",
  :count = "count",
  :search-in-progress ="searchInProgress"
  )
tile-line(v-if ="query && products && products.length && !searchInProgress", :products = "products")
contacts-line
</template>

<script>
import TheSearch from '../components/search/TheSearch'
import TileLine from '../components/_tiles/TileLine'
import { getSearchResults } from '../assets/scripts/API'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'SearchPage',
  components: { ContactsLine, HeadMeta, TileLine, TheSearch },
  data () {
    return {
      products: [],
      count: 0,
      query: '',
      searchInProgress: false
    }
  },
  methods: {
    async sendRequest (query) {
      this.searchInProgress = true
      const response = await getSearchResults(query)
      this.query = query
      this.products = response.products
      this.count = response.products_count
      this.searchInProgress = false
    },
    updateSearch (query) {
      this.$router.push({ name: 'Search', query: { query: query } })
      this.products = []
      this.count = 0
      this.sendRequest(query)
    },
    refreshSearchResults (query) {
      if (query && query.length > 2) {
        this.sendRequest(query)
      }
    }
  },
  mounted () {
    const searchQuery = this.$route.query.query
    this.refreshSearchResults(searchQuery)
  },
  async beforeRouteEnter (to, from, next) {
    const searchQuery = to.query.query
    if (searchQuery && searchQuery.length > 2) {
      const response = await getSearchResults(searchQuery)
      next(vm => {
        vm.products = response.products
        vm.query = searchQuery
        vm.count = response.products_count
      })
    } else {
      next()
    }
  },
  beforeRouteUpdate (to, from, next) {
    const searchQuery = to.query.query
    this.refreshSearchResults(searchQuery)
    next()
  }
}
</script>
